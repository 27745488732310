import React, { useEffect} from "react"
import { useOutletContext } from "react-router-dom"
import Loader from "../../../../components/ui/Loader"
import { joinClasses } from "../../../../utils/joinClasses"
import cl from "./style.module.sass"
import { Helmet } from "react-helmet"

export const OrderInformation = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
      }, []);  

    const context: any = useOutletContext()
    if(!context) return <Loader />
    const ctx = context.orderData
    if(!ctx){
        return <Loader />
    }

    console.log("ctx", ctx);

    return  (
        <div className={cl["table"]}>

            <Helmet> {/* Add the Helmet component */}
                <title>Order Information for LEI Code {ctx.lei_number} | LEIpapa</title>
                <meta 
                name="description" 
                content={`Order information for LEI ${ctx.lei_number}, legal name ${ctx.company_name}, Entity status ${ctx.entity_status}, Next renewal date ${ctx.next_renewal_date.slice(0, 19).replace("T", " ")}`} 
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <div className={cl["row-head"]}>
                <div className={cl["item-head-title"]}>Order Tracking Code</div>
                <div className={cl["item-head"]}>{ctx.order_tracking_code}</div>
                <div className={cl["item-head-title"]}>Order Creation Date</div>
                <div className={cl["item-head"]}>{ctx.created_at.slice(0, 19).replace("T", " ")}</div>
            </div>
            <div className={cl["row-head-company-name"]}>
                <div className={cl["item-head-title"]}>Legal Name</div>
                <div className={cl["item-head"]}>{ctx.company_name}</div>
                <div className={cl["item-head-title"]}>Company Number</div>
                <div className={cl["item-head"]}>{ctx.company_number}</div>
            </div>
            <div className={cl["row-head-lei"]}>
            <div className={cl["item-head-title"]}>LEI code issued</div>
            <div className={cl["item-head"]}>
                <a href={`/record-details/${ctx.lei_number}`} title="Go to the LEI Record Details">{ctx.lei_number}</a>
            </div>
            <div className={cl["item-head-title"]}>Order Status</div>
            <div className={`${cl["item-head"]} ${ctx.status === "Complete" ? cl["status-complete"] : ""}`}>{ctx.status}</div>
            </div>
            <div className={joinClasses(cl["row"], cl["subvalues"]) }>
                <div className={cl["item-title"]}>Entity Status</div>
                <div className={cl["item"]}>{ctx.entity_status}</div>
                <div className={cl["item-title"]}>Entity Type</div>
                <div className={cl["item"]}>{ctx.entity_type}</div>
            </div>
            <div className={cl["row"]}>
                <div className={cl["item-title"]}>Registration Authority</div>
                <div className={cl["item"]}>{ctx.registration_authority}</div>
                <div className={cl["item-title"]}>Registration Authority ID</div>
                <div className={cl["item"]}>{ctx.registration_authority_id}</div>
            </div>
            <div className={cl["row"]}>
                <div className={cl["item-title"]}>Entity Legal Form</div>
                <div className={cl["item"]}>{ctx.entity_legal_form}</div>
                <div className={cl["item-title"]}>Entity Creation Date</div>
                {ctx.entity_creation_date && <div className={cl["item"]}>{ctx.entity_creation_date.slice(0, 19).replace("T", " ")}</div>}
            </div>
            <div className={cl["row"]}>
                <div className={cl["item-title"]}>First Name</div>
                <div className={cl["item"]}>{ctx.sa_first_name}</div>
                <div className={cl["item-title"]}>Last Name</div>
                <div className={cl["item"]}>{ctx.sa_last_name}</div>
            </div>
            {/* <hr className={cl["hr"]} /> */}
            <div className={joinClasses(cl["row"], cl["subvalues"]) }>
                <div className={cl["item-title"]}>Years to Support</div>
                <div className={cl["item"]}>{ctx.multi_year_support}</div>
                <div className={cl["item-title"]}>Net Price</div>
                <div className={cl["item"]}>€{ctx.paid_amount}</div>
            </div>
        </div>
    ) 
}